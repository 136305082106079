<template>
  <div class="trainee-list-modal-overlay" @click="closeModalOutside">
    <div class="trainee-list-modal" @click.stop>
      <div class="modal-content">
        <h2>Edit Chapter ID: {{ newChapter.chapter_id }}</h2>

        <div class="add">
          <form @submit.prevent="updateChapter">
            <div class="form-group">
              <label for="chapt_no">Chapt No:</label>
              <input type="text" class="form-control rounded" id="chapt_no" name="chapt_no"
                placeholder="Enter Chapter No" v-model="newChapter.chapt_no" />
            </div>
            <div class="form-group">
              <label for="name">Name:</label>
              <input type="text" class="form-control rounded" id="chapter_name" name="chapter_name" placeholder="Enter Chapter Name"
                v-model="newChapter.name" />
            </div>
            <div v-if="showAlert" class="alert alert-danger error-message" role="alert">
              <div v-if="errorMessage">{{ errorMessage }}</div>
            </div>
            <div>
              <button class="btn btn-primary rounded" type="submit">Update Chapter</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  data() {
    return {
      allChapters: [],
      chapters: [],
      newChapter: {
        chapt_no: '',
        name: '',
      },
      showAlert: false,
      errorMessage: '',
      processing: false,
      showTraineeList: false,
    };
  },
  methods: {
    async updateChapter() {
      try {
        // Get the current chapter ID from localStorage
        const chapter_id = parseInt(localStorage.getItem('chapter_id'));
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        
        // Convert to lowercase and trim spaces for comparison
        const normalizedNewChapterNo = this.newChapter.chapt_no.trim().toLowerCase();
        const normalizedNewChapterName = this.newChapter.name.trim().toLowerCase();

        // Check for duplicate chapter number, excluding the current chapter being updated
        const isNumberDuplicate = this.allChapters.some(
          chapter =>
            chapter.chapt_no.trim().toLowerCase() === normalizedNewChapterNo &&
            chapter.chapter_id !== chapter_id
        );

        // Check for duplicate chapter name, excluding the current chapter being updated
        const isNameDuplicate = this.allChapters.some(
          chapter =>
            chapter.name.trim().toLowerCase() === normalizedNewChapterName &&
            chapter.chapter_id !== chapter_id
        );

        if (isNumberDuplicate) {
          console.error("Chapter number already exists. Please choose a different number.");
          window.alert('Chapter number already exists');
        } else if (isNameDuplicate) {
          console.error("Chapter name already exists. Please choose a different name.");
          window.alert('Chapter name already exists');
        } else {
          // Proceed with the update request if no duplicates are found
          const response = await axios.put(
            `/api/chapters/${chapter_id}`,
            {
              chapt_no: this.newChapter.chapt_no,
              name: this.newChapter.name,
            },
            { headers }
          );

          if (response.status === 200) {
            window.alert('Chapter updated successfully');
            this.chapters.push(response.data);  // Update the local chapters list
            this.newChapter = {
              chapt_no: '',
              name: '',
            };
            this.errorMessage = '';
            this.showAlert = false;
            this.closeModalOutside();
          }
        }
      } catch (error) {
        console.error('Error updating Chapter:', error.response);

        if (error.response && error.response.data && error.response.data.errors) {
          // Handle validation errors
          const validationErrors = error.response.data.errors;
          let errorMessage = 'Validation errors:';
          for (const field in validationErrors) {
            errorMessage += `\n${field}: ${validationErrors[field][0]}`;
          }
          this.errorMessage = errorMessage;
        } else {
          // Handle other errors
          this.errorMessage = 'An error occurred while updating the Chapter.';
        }

        this.showAlert = true;
      }
    },

    async fetchChapters() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/chapters', { headers });

        if (response.status === 200) {
          this.allChapters = response.data;
        }
      } catch (error) {
        console.error('Error retrieving chapters:', error);
        // Handle error
      }
    },
    viewTraineeList() {
      this.showTraineeList = true; // Show the modal when the button is clicked
    },
    closeTraineeList() {
      this.showTraineeList = false; // Close the modal
    },
    goBack() {
      this.$router.push({ name: 'ViewChapters' });
    },
    closeModalOutside() {
      this.$emit('close'); // Emit an event to indicate the modal should be closed
    },
    getChapterData(chapter_id) {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        axios.get(`/api/chapters/${chapter_id}`, { headers })
          .then(res => {
            this.newChapter = res.data;
            console.log(res.data);
          });
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    },
  },
  async mounted() {
    this.getChapterData(localStorage.getItem('chapter_id'));
    this.fetchChapters();
  },
}
</script>

<style scoped>
.trainee-list-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure the modal is on top of other content */
}

h2 {
  margin-top: 30px;
  font-size: 30px;
  margin-bottom: 30px;
}

/* Style your modal content */
.trainee-list-modal {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 1000px;
  /* Limit the maximum width of the modal */
  padding: 20px;
  position: relative;
  max-height: 95%;

}


.modal-content {
  overflow-y: auto;
  /* Enable scrolling within the modal content */
}


.add button {
  width: 200px;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.btn:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

input.form-control,
select.form-control {
  width: 70%;
  margin-left: 100px;
  display: inline-block;
  margin-bottom: 25px;
  align-items: baseline;
}

.form-group {
  margin-bottom: 15px;
}

label {
  margin-right: 5px;
  position: absolute;
  margin-top: 8px;
}

.form-control {
  border-radius: 5px;
}

.form-control#start_date {
  border-radius: 5px;
  width: 25%;
  margin-left: 100px;
  margin-right: 45%;
}

h1 {
  margin-bottom: 20px;
}

.alert-danger {
  color: #ff0019;
  background-color: #f8d7da;
  border: 1px solid #e6b0b6;
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 0rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}
</style>