<template>
  <div>
    <AdminHeader />
    <div class="container card">
      <h1>Add User</h1>
      <div class="add">
        <form @submit.prevent="addUser">
          <div class="form-group">
            <label for="name">First Name: *</label>
            <input type="text" class="form-control rounded" id="first_name" name="first_name" placeholder="Enter First Name"
              v-model="newUser.first_name" />
          </div>
          <div class="form-group">
            <label for="name">Last Name: *</label>
            <input type="text" class="form-control rounded" id="last_name" name="last_name" placeholder="Enter Last Name"
              v-model="newUser.last_name" />
          </div>
          <div class="form-group">
            <label for="role">Role: *</label>
            <select class="form-control rounded" id="role" name="role" v-model="newUser.role">
              <option value=""></option>
              <option value="practical_manager">Practical Element Manager</option>
              <option value="session_manager">Classroom Session Manager</option>
              <option value="instructor">Instructor</option>
              <option value="trainee">Trainee</option>
            </select>
          </div>
          <div class="form-group" v-if="newUser.role">
            <label for="second_role">Second Role: (optional)</label>
            <select class="form-control rounded" id="second_role" name="second_role" v-model="newUser.second_role">
              <option value=""></option>
              <option v-for="role in filteredSecondRoleOptions" :key="role" :value="role">{{ formatRole(role) }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="email">Email: *</label>
            <input type="text" class="form-control rounded" id="email" name="email" placeholder="Enter Email Address"
              v-model="newUser.email" />
          </div>
          <div class="form-group">
            <label for="category">Category:</label>
            <input type="text" class="form-control rounded" id="category" name="category" placeholder="Enter Category"
              v-model="newUser.category" />
          </div>
          <div class="form-group">
            <label for="department">Department:</label>
            <input type="text" class="form-control rounded" id="department" name="department"
              placeholder="Enter Department" v-model="newUser.department" />
          </div>
          <div class="form-group">
            <label for="license_no">License No:</label>
            <input type="text" class="form-control rounded" id="license_no" name="license_no"
              placeholder="Enter License No" v-model="newUser.license_no" />
          </div>
          <div class="form-group">
            <label for="date_of_birth">Date of Birth:</label>
            <input type="date" class="form-control rounded" id="date_of_birth" name="date_of_birth"
              placeholder="YYYY-MM-DD" v-model="newUser.date_of_birth" />
          </div>
          <div class="form-group">
            <label for="place_of_birth">Place of Birth:</label>
            <input type="text" class="form-control rounded" id="place_of_birth" name="place_of_birth"
              placeholder="Enter Place of Birth" v-model="newUser.place_of_birth" />
          </div>
          <div class="form-group">
            <div class="password-input">
              <label for="password">Password: *</label>
              <input :type="showPassword ? 'text' : 'password'" class="form-control rounded" id="password"
                name="password" placeholder="Create Password" v-model="newUser.password" />
              <i class="eye-icon" @click="toggleShowPassword"
                :class="showPassword ? 'far fa-eye' : 'far fa-eye-slash'"></i>
            </div>
          </div>
          <div class="form-group">
            <label for="reenteredPassword">Re-enter Password: *</label>
            <input :type="showPassword ? 'text' : 'password'" class="form-control rounded" id="reenteredPassword"
              name="reenteredPassword" placeholder="Re-enter Password" v-model="newUser.reenteredPassword" />
          </div>
          <div v-if="showAlert" class="alert alert-danger error-message" role="alert"> {{ errorMessage }}
          </div>
          <div>
            <button class="btn btn-primary rounded" type="submit">Add User</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>



<script>
import axios from 'axios';
import AdminHeader from './AdminHeader.vue';

export default {
  name: 'AddUser',
  components: {
    AdminHeader
  },
  data() {
    return {
      users: [],
      newUser: {
        first_name: '',
        last_name: '',
        role: '',
        second_role: '',
        password: '',
        reenteredPassword: '',
        email: '',
        department: '',
        license_no: '',
        category: '',
        date_of_birth: '',
        place_of_birth: '',
      },
      showAlert: false,
      currentTime: '',
      showPassword: false,
      errorMessage: '',
      loggedInUser: '',
    };
  },
  computed: {
    filteredSecondRoleOptions() {
      // Define all possible roles
      const roles = ['practical_manager', 'session_manager', 'instructor', 'trainee'];
      // Exclude the selected `newUser.role`
      return roles.filter(role => role !== this.newUser.role);
    }
  },
  methods: {
    formatRole(role) {
      // Format role names to be more readable (e.g., capitalize)
      return role.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    },
    async addUser() {
      try {
        if (this.newUser.password !== this.newUser.reenteredPassword) {
          this.errorMessage = 'Passwords do not match';
          this.showAlert = true;
          return;
        }

        const response = await axios.post(
          '/api/users',
          {
            first_name: this.newUser.first_name,
            last_name: this.newUser.last_name,
            role: this.newUser.role,
            second_role: this.newUser.second_role,
            email: this.newUser.email,
            department: this.newUser.department,
            license_no: this.newUser.license_no,
            category: this.newUser.category,
            date_of_birth: this.newUser.date_of_birth,
            place_of_birth: this.newUser.place_of_birth,
            password: this.newUser.password,
          }
        );

        if (response.status === 200) {
          window.alert('User added Successfully')
          this.newUser = {
            first_name: '',
            last_name: '',
            role: '',
            second_role: '',
            email: '',
            department: '',
            license_no: '',
            category: '',
            date_of_birth: '',
            place_of_birth: '',
            password: '',
          };
          this.errorMessage = '';
          this.showAlert = false;
        }
        await this.fetchUsers();
      } catch (error) {
        console.error('Error adding User:', error);
        console.log(error);
        this.errorMessage = error.response.data.message;


        if (error.response && error.response.data && error.response.data.errors) {
          // Handle validation errors
          const validationErrors = error.response.data.errors;

          // Build an error message from the validation errors
          let errorMessage = 'Validation errors:';
          for (const field in validationErrors) {
            errorMessage += `\n${field}: ${validationErrors[field][0]}`;
          }

          this.errorMessage = errorMessage;
        }

        this.showAlert = true;
      }
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword; // Toggle the showPassword property
    },

    async fetchUsers() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/users', { headers });
        console.log(response);

        if (response.status === 200) {
          this.users = response.data.users;
        }
      } catch (error) {
        console.error('Error retrieving users:', error);
        // Handle error
      }
    },
    async fetchLoggedInUser() {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get('/api/users', { headers });
        this.loggedInUser = response.data.username;
      } catch (error) {
        console.error('An error occurred while fetching the logged-in user:', error);
      }
    },
  },
  async mounted() {
    await this.fetchUsers();
    this.fetchLoggedInUser();
  },
};
</script>

<style scoped>
.container.card {
  top: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 70%;
  bottom: 20px;
}

.view-trainees-button {
  background-color: #cfcfcf;
  /* Change the background color to your desired color */
  color: #000000;
  position: absolute;
  right: 3%;
  top: 10%;
  font-size: 14px;
}

.add button {
  width: 200px;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #1b78db;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

input.form-control,
select.form-control {
  width: 70%;
  margin-left: 180px;
  display: inline-block;
  margin-bottom: 25px;
  align-items: baseline;
  height: 40px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  margin-right: 5px;
  position: absolute;
  margin-top: 8px;
}

.form-control {
  border-radius: 5px;
}


h1 {
  margin-bottom: 20px;
}

.eye-icon {
  position: absolute;
  top: 15px;
  right: 5%;
  cursor: pointer;
  color: grey;
}

.password-input {
  position: relative;
}

.alert-danger {
  color: #ff0019;
  background-color: #f8d7da;
  border: 1px solid #e6b0b6;
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 0rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}
</style>