<template>
  <div class="trainee-list-modal-overlay" @click="closeModalOutside">
    <div class="trainee-list-modal" @click.stop>
      <div class="modal-content">
        <h2>Edit Logbook ID: {{ newLogbook.logbook_id }}</h2>

        <div class="add">
          <form @submit.prevent="updateLogbook">
            <!-- <div class="form-group">
              <label for="role">Practical Element:</label>
              <select class="form-control rounded" id="product_id" name="product_id" 
              v-model="newLogbook.product_id" @change="fetchProjectsByProduct">
                <option value=""></option>
                <option v-for="product in products" :key="product.product_id" :value="product.product_id">
                  {{ product.name }}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="role">Session:</label>
              <select class="form-control rounded" id="project_id" name="project_id" 
              v-model="newLogbook.project_id"
                >
                <option value=""></option>
                <option v-for="project in projects" :key="project.project_id" :value="project.project_id">
                  {{ project.name }}</option>
              </select>
            </div> -->
            <div class="form-group">
              <label for="name">Name:</label>
              <input type="text" class="form-control rounded" id="logbook_name" name="logbook_name" placeholder="Enter Logbook Name"
                v-model="newLogbook.name" />
            </div>
            <!-- <div class="form-group">
              <label for="role">Instructor:</label>
              <select class="form-control rounded" id="instructor_id" name="instructor_id" v-model="newLogbook.instructor_id">
                <option value=""></option>
                <option v-for="instructor in instructors" :key="instructor.instructor_id" :value="instructor.user_id">
                ID: {{instructor.user_id }} &nbsp;&nbsp;&nbsp; -- &nbsp;&nbsp;&nbsp; {{ instructor.first_name + ',' + instructor.last_name }}</option>
              </select>
            </div> -->
            <div v-if="showAlert" class="alert alert-danger error-message" role="alert">
              <div v-if="errorMessage">{{ errorMessage }}</div>
            </div>
            <div>
              <button class="btn btn-primary rounded" type="submit">Update Logbook</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import axios from 'axios';

export default {
  data() {
    return {
      instructors: [],
      products: [],
      projects: [],
      logbooks: [],
      trainees: [],
      newLogbook: {
        name: '',
        product_id: '',
        project_id: '',
        trainee_id: '',
        instructor_id: '',
      },
      showAlert: false,
      currentTime: '',
      errorMessage: '',
      processing: false,
      productSelected: false,
      projectSelected: false,
      showTraineeList: false,
    };
  },
  methods: {

    async updateLogbook() {
      try {
        const logbook_id = localStorage.getItem('logbook_id');
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.put(
          `/api/logbooks/${logbook_id}`,
          {
            name: this.newLogbook.name,
            product_id: this.newLogbook.product_id,
            project_id: this.newLogbook.project_id,
            trainee_id: this.newLogbook.user_id,
            instructor_id: this.newLogbook.instructor_id,
          },
          { headers }
        );

        console.log('update')
        console.log(response);

        if (response.status === 200) {
          window.alert('Logbook updated Successfully');
          this.logbooks.push(response.data);
          this.newLogbook = {
            name: '',
            product_id: '',
                project_id: '',
                trainee_id: '',
                instructor_id: '',
          };
          this.errorMessage = '';
          this.showAlert = false;
        }
        await this.fetchProjects();
        window.location.reload();

      } catch (error) {
        console.error('Error adding User:', error.response);


        if (error.response && error.response.data && error.response.data.errors) {
          // Handle validation errors
          const validationErrors = error.response.data.errors;

          // Build an error message from the validation errors
          let errorMessage = 'Validation errors:';
          for (const field in validationErrors) {
            errorMessage += `\n${field}: ${validationErrors[field][0]}`;
          }

          this.errorMessage = errorMessage;
        } else {
          // Handle other errors
          this.errorMessage = 'An error occurred while updating the logbook.';
        }

        this.showAlert = true;
      }
    },

    async fetchProjects() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/projects', { headers });
        console.log('project response')
        console.log(response);

        if (response.status === 200) {
          this.projects = response.data;
          console.log('projects');
          console.log(this.projects);
        }
      } catch (error) {
        console.error('Error retrieving Sessions:', error);
        // Handle error
      }
    },
    async fetchProducts() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/products', { headers });

        if (response.status === 200) {
          this.products = response.data; // Populate the products array with API data
          console.log('this products')
          console.log(this.products);
        }
      } catch (error) {
        console.error('Error retrieving Practical Elements:', error);
        // Handle error
      }
    },
    async fetchInstructors() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/users', { headers });

        if (response.status === 200) {
          // Filter the response data to include only users with the role 'instructor'
          this.instructors = response.data.filter(user => user.role === 'instructor' || user.second_role === 'instructor');
          console.log(this.instructors);
        }
      } catch (error) {
        console.error('Error retrieving instructors:', error);
        // Handle error
      }
    },
    async fetchProjectsByProduct() {
            // Fetch projects based on the selected product
            this.onProductSelected();
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.display('unauthorized');
                    return;
                }

                const headers = {
                    Authorization: `Bearer ${token}`,
                };
                const response = await axios.get(`/api/products/${this.newLogbook.product_id}/projects`, { headers });

                if (response.status === 200) {
                    this.projects = response.data;
                    console.log('projects');
                    console.log(response);
                }
            } catch (error) {
                console.error('Error retrieving Sessions:', error);
                // Handle error
            }
        },
        onProductSelected() {
            this.productSelected = !!this.newLogbook.product_id;
        },
    viewTraineeList() {
      this.showTraineeList = true; // Show the modal when the button is clicked
    },
    closeTraineeList() {
      this.showTraineeList = false; // Close the modal
    },
    goBack() {
      this.$router.push({ name: 'ViewLogbooks' });
    },
    closeModalOutside() {
      this.$emit('close'); // Emit an event to indicate the modal should be closed
    },
    getLogbookData(logbook_id) {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        axios.get(`/api/logbooks/${logbook_id}`, { headers })
          .then(res => {
            this.newLogbook = res.data;
            console.log(res.data);
          });
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    },
  },
  async mounted() {
    this.getLogbookData(localStorage.getItem('logbook_id'));
    await this.fetchInstructors();
    await this.fetchProjects();
    this.fetchProducts();

  },
}
</script>
  
<style scoped>
.trainee-list-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure the modal is on top of other content */
}

h2 {
  margin-top: 30px;
  font-size: 30px;
  margin-bottom: 30px;
}

/* Style your modal content */
.trainee-list-modal {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 1000px;
  /* Limit the maximum width of the modal */
  padding: 20px;
  position: relative;
  max-height: 95%;

}


.modal-content {
  overflow-y: auto;
  /* Enable scrolling within the modal content */
}


.add button {
  width: 200px;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

button.btn:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

input.form-control,
select.form-control {
  width: 70%;
  margin-left: 100px;
  display: inline-block;
  margin-bottom: 25px;
  align-items: baseline;
}

.form-group {
  margin-bottom: 15px;
}

label {
  margin-right: 5px;
  position: absolute;
  margin-top: 8px;
}

.form-control {
  border-radius: 5px;
}

.form-control#start_date {
  border-radius: 5px;
  width: 25%;
  margin-left: 100px;
  margin-right: 45%;
}

h1 {
  margin-bottom: 20px;
}

.alert-danger {
    color: #ff0019;
    background-color: #f8d7da;
    border: 1px solid #e6b0b6;
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 0rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}
</style>
  