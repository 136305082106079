<template>
    <div>
        <Header />
        <div class="container card">
            <button class="back-button" @click="goBack">Back</button>
            <h1>Create Logbook Form</h1>
            <div class="add">
                <form @submit.prevent="addLogbook">
                    <div class="form-group">
                        <label for="role">Practical Element:</label>
                        <select class="form-control rounded" id="product_id" name="product_id"
                            v-model="newLogbook.product_id" @change="fetchProjectsByProduct">
                            <option value=""></option>
                            <option v-for="product in products" :key="product.product_id" :value="product.product_id">
                                {{ product.name }}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="role">Session:</label>
                        <select class="form-control rounded" id="project_id" name="project_id"
                            v-model="newLogbook.project_id" :disabled="!productSelected">
                            <option value=""></option>
                            <option v-for="project in projects" :key="project.project_id" :value="project.project_id">
                                {{ project.name }}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="name">Base Name:</label>
                        <input type="text" class="form-control rounded" id="logbook_name" name="logbook_name"
                            placeholder="Enter Logbook Name" v-model="newLogbook.name" />
                    </div>
                    <div class="centered-text">
                        <p>
                            <b>Note: </b> This will create a logbook for each trainee in the Session <br>
                            <b>Note: </b> The Trainee's full name appends to the end of the Base Name in the form: {Base
                            Name - Trainee Name}
                        </p>
                    </div>
                    <div v-if="showAlert" class="alert alert-danger error-message" role="alert">
              <div v-if="errorMessage">{{ errorMessage }}</div>
            </div>
                    <div>
                        <button class="btn btn-primary rounded" type="submit" :disabled="processing">
                            {{ processing ? 'Processing...' : 'Create' }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
  
    
    
    
<script>
import axios from 'axios';
import Header from '../Header.vue';
export default {
    name: 'AddLogbook',
    components: {
        Header,
    },
    data() {
        return {
            products: [],
            projects: [],
            logbooks: [],
            trainees: [],
            instructors: [],
            newLogbook: {
                name: '',
                product_id: '',
                project_id: '',
                trainee_id: '',
            },
            showAlert: false,
            currentTime: '',
            errorMessage: '',
            processing: false,
            productSelected: false,
            projectSelected: false,
            showTraineeList: false,
        };
    },
    watch: {
        'newLogbook.project_id': function (newProjectId) {
            if (newProjectId && this.projects) {
                const selectedProject = this.projects.find(project => project.project_id === newProjectId);
                if (selectedProject) {
                    this.newLogbook.name = selectedProject.name + ' Logbook';
                }
            }
        }
    },
    methods: {
        async sendBatchEmailToTrainees(traineesWithoutLogbooks) {
    try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        // Map trainees array to an array of data objects for the API call
        const emailDataArray = traineesWithoutLogbooks.map(trainee => ({
            first_name: trainee.first_name,
            email: trainee.email,
            // Add any additional data needed for the email
        }));

        const response = await axios.post(
            '/api/batch-contact', // Update the API endpoint to handle batch emails
            emailDataArray,
            { headers }
        );

        if (response.status === 200) {
            console.log('Batch emails sent successfully');
        }
    } catch (error) {
        console.error('Error sending batch emails:', error);
        // Handle error as needed
    }
},
        async addLogbook() {
            //eslint-disable-next-line
            debugger;
            this.processing = true; // Set processing to true
            try {
                const token = localStorage.getItem('token');
                const headers = { Authorization: `Bearer ${token}` };
                const response = await axios.get(
                    `/api/projects/${this.newLogbook.project_id}/users`,
                    { headers }
                );
                if (response.status === 200) {
                    this.trainees = response.data.filter(user => user.role === 'trainee' || user.second_role === 'trainee');
                    console.log(this.trainees);
                    // Get instructor user ids
                    const instructorUserIds = this.getInstructorUserIds(response.data);
                    console.log('INS User IDs:', instructorUserIds);

                    // Find the selected project based on project_id
                    const selectedProject = this.projects.find(cls => cls.project_id === this.newLogbook.project_id);
                    if (!selectedProject) {
                        console.error('Session not found.');
                        return; // Exit early if the project is not found
                    }
                    // Identify trainees who don't have a logbook for the selected project
                    const traineesWithoutLogbooks = this.trainees.filter(trainee => {
                        // Check if there is no logbook for the current trainee and the trainee belongs to the selected project
                        return !this.logbooks.some(logbook => logbook.trainee_id === trainee.user_id && logbook.project_id === this.newLogbook.project_id);
                    });
                    console.log(`Trainees wo Logbooks ${traineesWithoutLogbooks}`);
                    // Create logbooks only for trainees without logbooks
                    const logbooksData = traineesWithoutLogbooks.map(trainee => ({
                        name: `${this.newLogbook.name} - ${trainee.first_name} ${trainee.last_name}`,
                        product_id: this.newLogbook.product_id,
                        project_id: this.newLogbook.project_id,
                        trainee_id: trainee.user_id,
                    }));
                    try {
                        const logbooksResponse = await axios.post('/api/logbooks/batch', logbooksData, { headers });
                        console.log(logbooksResponse);
                        console.log(logbooksResponse.data.logbooks);
                        // Extract the created logbook ids from the response
                        const logbookIds = logbooksResponse.data.logbooks.map(logbook => logbook.logbook_id);
                        console.log(instructorUserIds);
                        console.log(logbookIds);

                        await axios.post('/api/logbooks/add-users-to-logbook', {
                            project_id: this.newLogbook.project_id,
                            user_ids: instructorUserIds,
                            logbook_ids: logbookIds, 
                        }, { headers });
                        console.log(traineesWithoutLogbooks);
                            //await this.sendBatchEmailToTrainees(traineesWithoutLogbooks);
                    } catch (error) {
                        if (error.response && error.response.status === 400) {
                            console.error('A logbook with the same trainee and Session already exists:', error);
                            // You can handle this error if needed, but it won't stop the loop.
                        } else {
                            console.error('Error adding logbook for trainee ID:', error);
                            // You can handle other errors here as needed.
                        }
                    }
                }
                window.alert('Logbook(s) added Successfully');
                this.newLogbook = {
                    name: '',
                    product_id: '',
                    project_id: '',
                    trainee_id: '',
                };
                this.errorMessage = '';
                this.showAlert = false;
                this.$router.push({ name: 'ViewLogbooks' });
                this.processing = false; // Set processing back to false
            } catch (error) {
                if (error.response && error.response.status === 500) {
                    console.error('Internal Server Error. Please try again later.', error);
                    this.errorMessage = 'Internal Server Error. Please try again later.';
                } else if (error.response.data.message === 'The route api/projects//users could not be found.') {
                    console.error('An unknown error occurred while adding the logbook.', error);
                    this.errorMessage = 'Please select a Practical Element and a Session';
                } else if (error.message) {
                    console.error('An unknown error occurred while adding the logbook.', error);
                    this.errorMessage = error.response.data.message;
                } else {
                    console.error('Error retrieving trainees:', error);
                    this.errorMessage = error.response.data.message;
                }
                this.showAlert = true;
            } finally {
                this.processing = false; // Set processing back to false
            }
        },

        async initializeTasksForLogbook(productId, logbookIds, headers) {
            try {
                // Fetch tasks based on productId
                const taskResponse = await axios.get(`/api/tasks/products/${productId}`, { headers });
                const tasks = taskResponse.data.map(task => ({
                    task_id: task.task_id,  // Ensure the field name 'id' matches the API response
                    enabled: true,     // Set as enabled
                }));

                // Loop through each logbook ID and batch update tasks for it
                for (let logbookId of logbookIds) {
                    await this.batchUpdateTasks(logbookId, tasks, headers);
                }
            } catch (error) {
                console.error('Error initializing tasks for logbooks:', error);
            }
        },

        async batchUpdateTasks(logbookId, tasks, headers) {
    try {
        await axios.post('/api/logbookentries/batch-update', {
            logbook_id: logbookId,
            tasks: tasks
        }, { headers });
        console.log(`Batch update successful for logbook ID ${logbookId}`);
    } catch (error) {
        console.error(`Error during batch update for logbook ID ${logbookId}:`, error);
    }
},

        async fetchLogbooks() {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.display('unauthorized');
                    return;
                }
                const headers = {
                    Authorization: `Bearer ${token}`,
                };
                const response = await axios.get('/api/logbooks', { headers });
                console.log(response);
                if (response.status === 200) {
                    this.projects = response.data.projects;
                    this.logbooks = response.data;
                }
            } catch (error) {
                console.error('Error retrieving logbooks:', error);
                // Handle error
            }
        },
        getInstructorUserIds(users) {
            // Filter users with role 'instructor' and extract user ids
            return users.filter(user => user.role === 'instructor' || user.second_role === 'instructor' || user.role === 'assessor' || user.second_role === 'assessor').map(user => user.user_id);
        },
        async fetchProducts() {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.display('unauthorized');
                    return;
                }
                const headers = {
                    Authorization: `Bearer ${token}`,
                };
                const response = await axios.get('/api/products', { headers });
                if (response.status === 200) {
                    this.products = response.data; // Populate the products array with API data
                    console.log(this.products);
                }
            } catch (error) {
                console.error('Error retrieving Practical Elements:', error);
                // Handle error
            }
        },
        async fetchProjectsByProduct() {
            // Fetch projects based on the selected product
            this.onProductSelected();
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.display('unauthorized');
                    return;
                }
                const headers = {
                    Authorization: `Bearer ${token}`,
                };
                const response = await axios.get(`/api/products/${this.newLogbook.product_id}/projects`, { headers });
                if (response.status === 200) {
                    this.projects = response.data; // Update the projects array with the fetched projects
                }
            } catch (error) {
                console.error('Error retrieving Sessions:', error);
                // Handle error
            }
        },
        onProductSelected() {
            this.productSelected = !!this.newLogbook.product_id;
        },
        onProjectSelected() {
            this.projectSelected = !!this.newLogbook.project_id;
        },
        viewTraineeList() {
            this.showTraineeList = true; // Show the modal when the button is clicked
        },
        closeTraineeList() {
            this.showTraineeList = false; // Close the modal
        },
        goBack() {
            this.$router.push({ name: 'ViewLogbooks' });
        },
    },
    async mounted() {
        await this.fetchLogbooks();
        this.fetchProducts();
    },
    computed: {
        logbookNameSuggestion() {
            if (this.newLogbook.project_id && this.projects) {
                const selectedProject = this.projects.find(project => project.project_id === this.newLogbook.project_id);
                if (selectedProject) {
                    return selectedProject.name + ' Logbook';
                }
            }
            return '';
        },
    }
};
</script>
    
<style scoped>
.container.card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    margin-top: 10px;
    min-width: 90%;
    min-height: 90%;
}

input.form-control,
select.form-control {
    width: 70%;
    margin-left: 200px;
    display: inline-block;
    margin-bottom: 35px;
    align-items: baseline;
}

.form-group {
    margin-bottom: 15px;
}

label {
    margin-right: 5px;
    position: absolute;
    margin-top: 8px;
    font-size: 18px;
}

.add button {
    width: 200px;
    height: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid 0f57b4;
    background: #0f57b4;
    color: white;
    cursor: pointer;
    text-align: center;
    transition: transform 0.2s, box-shadow 0.2s;
}

.centered-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

p {
    padding: 20px;
    color: #be0808;
    border: 1px solid rgb(204, 202, 202);
    width: 70%;
    border-radius: 5px;
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: math;
}

.form-control {
    border-radius: 5px;
}

h1 {
    margin-bottom: 50px;
}

.back-button {
    position: absolute;
    bottom: 40px;
    /* Adjust the top position as needed */
    left: 60px;
    /* Adjust the right position as needed */
    width: 100px;
    border-radius: 5px;
    background-color: #c1e0bf;
    font-size: 14px;
    border: 2px solid black;
    padding: 3px;
}

.back-button:hover {
    background-color: #abc7a9;
    cursor: pointer;
}

.alert-danger {
    color: #ff0019;
    background-color: #f8d7da;
    border: 1px solid #e6b0b6;
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 0rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}
</style>