<template>
  <head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
  </head>
  <div>
    <Header />

    <div class="current-time-container">
      <div class="filter-container">
        <select v-model="productFilter" id="productFilter" class="filter-select" @change="fetchProjectsByProduct">
          <option value="">All Practical Elements</option>
          <option v-for="product in products" :key="product.product_id" :value="product.product_id">{{ product.name }}
          </option>
        </select>


        <select v-model="projectFilter" id="projectFilter" class="filter-select" :disabled="!productFilter">
          <option value="">All Sessions</option>
          <option v-for="project in filteredProjects" :key="project.project_id" :value="project.project_id">{{
            project.name }}</option>
        </select>


        <input v-model="logbookSearch" type="text" placeholder="Search Logbook Name" />
        <input v-model="traineeSearch" type="text" placeholder="Search Trainee ID" />
        <input v-model="instructorSearch" type="text" placeholder="Search Instructor Name" />

        <button class="clear-filter-button" @click="clearFilters">Clear Filters</button>

      </div>
    </div>

    <h1>Logbooks</h1>

    <div class="pagination-button-container">
      <div class="pagination-container">
        <Pagination :totalPages="totalPages" :perPage="perPage" :currentPage="currentPage" @page-change="onPageChange" />
      </div>
      <div class="pagination-info">
        Showing {{ startIndex + 1 }}-{{ endIndex }} of {{ filteredLogbooksLength }} logbooks
      </div>
    </div>

    <edit-logbook-modal v-if="showEditLogbook" @close="closeEditLogbook"></edit-logbook-modal>

    <div class="container-fluid-logbook">
      <table class="data-table-trainee">
        <thead>
          <tr>
            <th @click="sortBy('logbook_id')" class="id-column">
              ID
              <span v-if="sortField === 'logbook_id'" v-html="getFieldArrow()"></span>
            </th>
            <th @click="sortBy('name')" class="wider-column">
              Logbook Name
              <span v-if="sortField === 'name'" v-html="getFieldArrow('name')"></span>
            </th>
            <th class="id-column">P.E. ID</th>
            <th class="id-column">Session ID</th>
            <th class="id-column">Trainee ID</th>
            <th>Instructor(s)</th>
            <th class="skinny-column-select">Actions</th>
            <!-- <th class="skinny-column">Delete Logbook</th>  -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in filteredLogbooks" :key="item.logbook_id">
            <td>{{ item.logbook_id }} </td>
            <td>{{ item.name }}</td>
            <td>{{ item.product_id }} </td>
            <td>{{ item.project_id }} </td>
            <td>{{ item.trainee_id }} </td>
            <td>
  <!-- Iterate over instructors for this logbook -->
  <span v-for="(instructor, index) in instructors" :key="index">
    <!-- Check if the instructor is associated with this logbook -->
    <span v-if="instructor.logbook_id === item.logbook_id">
      <!-- Display instructor's name -->
      {{ instructor.last_name }}, {{ instructor.first_name }} <br>
    </span>
  </span>
</td>
            <td class="center-icons">
              <i class="fas fa-edit edit-icon" @click="viewEditLogbook(item.logbook_id)"></i>
              <i class="fas fa-trash-alt delete-icon" @click="deleteLogbook(item.logbook_id)"></i>
              <!-- <button id="generatePDFButton" @click="selectTrainee(item.trainee_id, item.product_id)">PDF</button> -->
            </td>
            <!-- <td>
                <button class="delete-button" @click="deleteLogbook(item.logbook_id)">Delete</button>
              </td>  -->
          </tr>
          <tr v-if="logbooks.length === 0">
          <td colspan="100" style="text-align: center;">No Matching Logbooks</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div>
    <button class="btn btn-primary rounded" id=addlogbookButton @click="goAddLogbook">Create New Logbook</button>
  </div>
</template>
  
<script>
import Header from '../Header.vue';
import axios from 'axios';
import Pagination from '../TablePagination.vue';
import EditLogbookModal from './EditLogbookModal.vue';

export default {
  name: 'ViewLogbooks',

  components: {
    Pagination,
    Header,
    EditLogbookModal,
  },

  data() {
    return {
      logbooks: [],
      sortField: 'logbook_id',
      sortDirection: 'asc',
      currentPage: 1,
      perPage: 10,
      totalPages: 0,
      productFilter: '',
      projectFilter: '',
      traineeSearch: '',
      instructorSearch: '',
      logbookSearch: '',
      products: [],
      projects: [],
      instructors: [],
      tasks: [],
      trainees: [],
      selectedProductId: '',
      selectedTraineeId: '',
      showEditLogbook: false,
      instructorNames: {},
      filteredLogbooksLength: 0,
      joinedName: '',
      selectedTrainee: {
        trainee_id: null,
        first_name: '',
        last_name: '',
        email: '',
        category: '',
        license_no: '',
        date_of_birth: '',
        place_of_birth: '',
      },
    };
  },

  watch: {
    traineeSearch(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    instructorSearch(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    logbookSearch(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when searchQuery changes
        this.calculateTotalPages();
      }
    },
    productFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when productFilter changes
        this.fetchProjectsByProduct(); // Fetch projects based on the selected product
        this.calculateTotalPages();
      }
    },
    projectFilter(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1; // Reset currentPage to 1 when productFilter changes
        this.calculateTotalPages();
      }
    },
  },
  async mounted() {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      let result = await axios.get('/api/logbooks', { headers });
      this.logbooks = result.data;

      // Fetch all products from the server
      const productResponse = await axios.get('/api/products', { headers });
      this.products = productResponse.data; // Assign the entire array of products to this.products

      let resultInstructors = await axios.get('/api/user/logbooks', { headers });
      this.instructors = resultInstructors.data.user_logbooks;
      // Filter only the instructors
      this.instructors = this.instructors.filter(user => user.role === 'instructor' || user.second_role === 'instructor');
      console.log('instructors', this.instructors);   

      // let instructorProject = await axios.get('/api/projects/{project_id}/users', { headers });

      this.totalPages = Math.ceil(this.logbooks.length / this.perPage);

    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  },

  methods: {
    getInstructorsForLogbook(logbookId) {
    // Filter the instructors array based on the logbook_id
    return this.instructors.filter(instructor => instructor.logbook_id === logbookId);
  },
  deleteLogbook(logbook_id) {
  if (confirm("Are you sure you want to delete this logbook?")) {
    if (confirm("This action will PERMANENTLY delete the logbook from the database. Do you wish to continue?")) {
      // If the user confirms the deletion, send a request to delete the logbook.
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      axios
        .delete(`/api/logbooks/${logbook_id}`, { headers })
        .then(response => {
          if (response.status === 200) {
            // Remove the deleted logbook from the local data
            this.logbooks = this.logbooks.filter(logbook => logbook.logbook_id !== logbook_id);

            window.alert('Logbook deleted Successfully');
          }
        })
        .catch(error => {
          // Check if the error is due to a foreign key constraint
          if (error.response && error.response.status === 500) {
            const errorMessage = error.response.data.message;
            const isForeignKeyConstraintError = errorMessage.includes('SQLSTATE[23000]') && errorMessage.includes('Integrity constraint violation: 1451');

            if (isForeignKeyConstraintError) {
              window.alert('This Logbook cannot be deleted because it contains one or more entries.');
            } else {
              window.alert('An error occurred while deleting the Logbook. Please try again.');
            }
          } else {
            console.error('An unexpected error occurred:', error);
            window.alert('An unexpected error occurred. Please try again.');
          }
        });
    }
  }
},
    async fetchProjectsByProduct() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        // Fetch projects based on the selected product (this.productFilter, which stores the product ID)
        const response = await axios.get(`/api/projects?product=${this.productFilter}`, { headers });

        if (response.status === 200) {
          this.projects = response.data;
        }

        // Enable or disable the project filter based on the product selection
        this.$nextTick(() => {
          const projectFilterSelect = document.getElementById('projectFilter');
          projectFilterSelect.disabled = !this.productFilter;
        });
      } catch (error) {
        console.error('Error retrieving Sessions:', error);
        // Handle error
      }
    },
    sortBy(field) {
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortField = field;
        this.sortDirection = 'asc';
      }

      this.logbooks.sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return (this.sortDirection === 'asc' ? 1 : -1) * (valueA - valueB);
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          return (this.sortDirection === 'asc' ? 1 : -1) * valueA.localeCompare(valueB);
        } else {
          return 0;
        }
      });
    },
    async fetchTraineeTasks(traineeId) {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        console.log(traineeId);

        // Fetch trainee logbooks using trainee ID
        const traineeLogbooksResponse = await axios.get(`api/trainee/${traineeId}/logbooks`, { headers });
        const traineeLogbooks = traineeLogbooksResponse.data.logbooks; // Assuming it returns an array of logbooks

        console.log(traineeLogbooks);

        // Fetch tasks in each logbook
        const tasksPromises = traineeLogbooks.map(async (logbook) => {
          const logbookId = logbook.logbook_id;
          const logbookEntryResponse = await axios.get(`api/logbookentry/${logbookId}`, { headers });
          return logbookEntryResponse.data.tasks; // Assuming it returns an array of tasks for each logbook
        });

        const tasksArray = await Promise.all(tasksPromises); // Array of arrays of tasks

        // Flatten the tasksArray to a single array
        const tasks = tasksArray.flat();


        return tasks; // Return the tasks array
      } catch (error) {
        console.error('An error occurred while fetching trainee logbooks or tasks:', error);
        throw error; // Propagate the error to the calling code if needed
      }
    },
    goAddLogbook() {
      this.$router.push({ name: 'AddLogbook' })
    },
    getFieldArrow(field = 'logbook_id') {
      if (this.sortField === field) {
        return this.sortDirection === 'asc' ? '▲' : '▼';
      } else {
        return '';
      }
    },
    onPageChange(page) {
      this.currentPage = page;

      this.calculateTotalPages();
    },
    // Add a method to handle changes in the search input field
    onTraineeSearch() {
      this.currentPage = 1; // Reset currentPage to 1 when search query changes
    },
    onLogbookSearch() {
      this.currentPage = 1; // Reset currentPage to 1 when search query changes
    },
    onInstructorSearch() {
      this.currentPage = 1; // Reset currentPage to 1 when search query changes
    },
    viewEditLogbook(logbook_id) {
      localStorage.setItem('logbook_id', logbook_id);
      this.showEditLogbook = true; // Show the modal when the button is clicked
    },
    closeEditLogbook() {
      this.showEditLogbook = false; // Close the modal
    },
    calculateTotalPages() {
      this.computeFilteredLogbooks();
      this.totalPages = Math.ceil(this.filteredLogbooksLength / this.perPage);
    },
    computeFilteredLogbooks() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const filteredLogbooks = this.logbooks.filter((logbook) => {
        const traineeMatch = this.traineeSearch === '' || logbook.trainee_id.toString() === this.traineeSearch;
        const logbookName = logbook.name.toLowerCase();
        const logbookMatch = logbookName.includes(this.logbookSearch.toLowerCase()) || this.logbookSearch === '';

        const projectMatch = this.projectFilter === '' || logbook.project_id === this.projectFilter;
        const productMatch = this.productFilter === '' || logbook.product_id === this.productFilter;

        const instructorMatch =
      this.instructorSearch === '' ||
      this.instructors.some(
        (instructor) =>
          instructor.logbook_id === logbook.logbook_id &&
          `${instructor.first_name} ${instructor.last_name}`
            .toLowerCase()
            .includes(this.instructorSearch.toLowerCase())
      );

        return traineeMatch && logbookMatch && projectMatch && productMatch && instructorMatch;

      });
      this.filteredLogbooksLength = filteredLogbooks.length
      return filteredLogbooks.slice(startIndex, startIndex + this.perPage);
    },
    clearFilters() {
      // Clear all filter-related data properties
      this.productFilter = '';
      this.projectFilter = '';
      this.traineeSearch = '';
      this.instructorSearch = '';
      this.logbookSearch = '';

      // Recalculate the total pages and update the data
      this.calculateTotalPages();
    },
  },

  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      const end = this.startIndex + this.perPage;
      return end < this.filteredLogbooksLength ? end : this.filteredLogbooksLength;
    },
    filteredProjects() {
      if (this.productFilter === '') {
        // If no product is selected, return all projects
        return this.projects;
      } else {
        // Filter projects based on the selected prod_id
        return this.projects.filter(project => project.prod_id === this.productFilter);
      }
    },
    filteredLogbooks() {
      return this.computeFilteredLogbooks();
    },
  },

}
</script>
  
  
<style scoped>
h2 .trainees-header,
.logbooks-header {
  margin-left: 5%;
  text-align: left;
}

span:hover {
  cursor: pointer;
  color: blue;
}

.container-fluid-logbook {
  padding-top: 30px;
  margin-left: 1%;
  margin-right: 1%;
}

input[type="text"] {
  width: 100%;
  height: 35px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 1px;
}



h1 {
  padding-top: 30px;
  margin-right: 100%;
  padding-left: 3%;
}

.filter-container {
  margin: 20% 0;
  position: absolute;
  width: 200px;
  top: 10px;
}

label {
  margin-top: 8px;
}

.filter-select#productFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 6px;
}

.filter-select#projectFilter {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
  position: absolute;
  right: 210px;
  top: 50px;
}

button.btn#addlogbookButton {
  padding: 10px 10px;
  font-size: 16px;
  width: 180px;
  height: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  float: right;
}

button.btn#addlogbookButton:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

.current-time-container {
  position: absolute;
  top: 90px;
  right: 210px;
  display: flex;
  align-items: center;
}

.data-table-trainee {
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-collapse: collapse;
  margin-left: 0;
  align-items: left;
  margin-bottom: 30px;
}

.data-table-trainee th,
.data-table-trainee td {
  text-align: left;
  /* Align content to the left */
  padding-left: 10px;
}

.data-table-trainee td.center-icons {
  text-align: center;
}

.pagination-container {
  margin-top: 40px;
  margin-bottom: 0;
  display: flex;
  margin-left: 3%;
}

.edit-icon {
  font-size: 16px;
  /* Adjust the size as needed */
  color: #615f5f;
  /* Change the color to your preference */
  cursor: pointer;
  transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
}

.edit-icon:hover {
  color: #2b2a2a;
  /* Change the color to your preference */
  cursor: pointer;
  transform: scale(1.1);
  /* Example transform for scaling on hover */

}

span {
  border: 0px;
}

span:hover {
  border: 0px;
  color: rgb(17, 17, 17);
  cursor: auto;
}

.delete-icon {
  font-size: 16px;
  color: #FF0000;
  /* Red color */
  cursor: pointer;
  transition: color 0.2s, transform 0.2s, box-shadow 0.2s;
  padding-left: 15px;
}

.delete-icon:hover {
  color: #a82222;
  /* Lighter red color on hover */
  cursor: pointer;
  transform: scale(1.1);
  /* Scale the icon on hover */
  /* You can also add a box-shadow or any other styles you prefer */
}

.skinny-column {
  width: 10%;
}

.id-column {
  width: 7%;
}

.data-table-trainee th.skinny-column-select {
  width: 10%;
  text-align: center;
}

.wider-column {
  width: 42%;
}


.filter-select#projectFilter:disabled {
  cursor: not-allowed;
}

.clear-filter-button {
  position: absolute;
  right: 250px;
  top: 92px;
  width: 120px;
  border-radius: 5px;
  background-color: #dee2e6;
  font-size: 14px;
  height: 30px;
  border: 2px solid black;
  line-height: 0px;
}

.clear-filter-button:hover {
  background-color: #cecece;
  cursor: pointer;
}

.pagination-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}


.pagination-info {
  font-size: 14px;
  color: #666;
  /* Adjust the color as needed */
  position: absolute;
  top: 250px;
  /* Adjust the top position as needed */
  /* Center horizontally within the container */
  left: 50%;
  /* Center horizontally */
  transform: translateX(-50%);
  /* Center horizontally */
}

button#generatePDFButton {
  border-radius: 5px;
  background-color: #c8e1fa;
  margin-left: 10px;
  font-size: 13px;
  padding: 3px;
  max-width: 40px;
  border: 2px solid black;
}

button#generatePDFButton:hover {
  background-color: #afc5db;
  cursor: pointer;
}
</style>
  