<template>
  <div class="trainee-list-modal-overlay" @click="closeModalOutside">
    <div class="trainee-list-modal" @click.stop>
      <div class="modal-content">
        <h2>Edit User ID: {{ newUser.user_id }}</h2>

        <div class="add">
          <form @submit.prevent="updateUser">
            <div class="form-group">
              <label for="name">First Name:</label>
              <input type="text" class="form-control rounded" id="first_name" name="first_name" placeholder="Enter First Name"
                v-model="newUser.first_name" />
            </div>
            <div class="form-group">
              <label for="name">Last Name:</label>
              <input type="text" class="form-control rounded" id="last_name" name="last_name" placeholder="Enter Last Name"
                v-model="newUser.last_name" />
            </div>
            <div class="form-group">
              <label for="email">Email:</label>
              <input type="text" class="form-control rounded" id="email" name="email" placeholder="Enter Email Address"
                v-model="newUser.email" />
            </div>
            <div class="form-group">
            <label for="license_no">License No:</label>
            <input type="text" class="form-control rounded" id="license_no" name="license_no" placeholder="License Number"
              v-model="newUser.license_no" />
          </div>
          <div class="form-group">
            <label for="category">Category:</label>
            <input type="text" class="form-control rounded" id="category" name="category" placeholder="Enter the Category"
              v-model="newUser.category" />
          </div>
          <div class="form-group">
            <label for="role">Role:</label>
            <select
              class="form-control rounded"
              id="role"
              name="role"
              v-model="newUser.role"
            >
              <option value=""></option>
              <option value="practical_manager">Practical Element Manager</option>
              <option value="session_manager">Classroom Session Manager</option>
              <option value="instructor">Instructor</option>
              <option value="trainee">Trainee</option>
            </select>
          </div>
          <div class="form-group" v-if="newUser.role">
            <label for="second_role">Second Role:</label>
            <select class="form-control rounded" id="second_role" name="second_role" v-model="newUser.second_role">
              <option value=""></option>
              <option v-for="role in filteredSecondRoleOptions" :key="role" :value="role">{{ formatRole(role) }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="department">Department:</label>
            <input type="text" class="form-control rounded" id="department" name="department" placeholder="Enter the Department"
              v-model="newUser.department" />
          </div>
          <div class="form-group">
            <label for="date_of_birth">Date of Birth:</label>
            <input type="text" class="form-control rounded" id="date_of_birth" name="date_of_birth" placeholder="Enter Date of Birth"
              v-model="newUser.date_of_birth" />
          </div>
          <div class="form-group">
            <label for="place_of_birth">Place of Birth:</label>
            <input type="text" class="form-control rounded" id="place_of_birth" name="place_of_birth" placeholder="Enter the Place of Birth"
              v-model="newUser.place_of_birth" />
          </div>
            <div class="form-group">
              <label for="password">Password:</label>
              <input type="password" class="form-control rounded" id="password" name="password"
                placeholder="Create Password" v-model="newUser.password" />
            </div>
            <div>
              <p>
                <b>Note: </b>Leave the password field empty if you do not wish to update it
              </p>
            </div>
            <div v-if="showAlert" class="alert alert-danger error-message" role="alert">
              <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
            </div>
            <div>
              <button class="btn btn-primary rounded" type="submit">Update User</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import axios from 'axios';

export default {
  data() {
    return {
      users: [],
      newUser: {
        first_name: '',
        last_name: '',
        password: '',
        email: '',
        license_no: '',
        role: '',
        second_role: '',
        category: '',
        department: '',
        date_of_birth: '',
        place_of_birth: '',
      },
      showAlert: false,
      currentTime: '',
      errorMessages: {},
      errorMessage: '',
      loggedInUser: '',
      showUserList: false,
    };
  },
  computed: {
    filteredSecondRoleOptions() {
      // Define all possible roles
      const roles = ['practical_manager', 'session_manager', 'instructor', 'trainee'];
      // Exclude the selected `newUser.role`
      return roles.filter(role => role !== this.newUser.role);
    }
  },
  methods: {
    formatRole(role) {
      // Format role names to be more readable (e.g., capitalize)
      return role.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    },
    async updateUser() {
      try {
        const user_id = localStorage.getItem('user_id');
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        // Create a data object with the fields you want to update
        const data = {
          first_name: this.newUser.first_name,
          last_name: this.newUser.last_name,
          email: this.newUser.email,
          license_no: this.newUser.license_no,
          role: this.newUser.role,
          second_role: this.newUser.second_role,
          category: this.newUser.category,
          department: this.newUser.department,
          date_of_birth: this.newUser.date_of_birth,
          place_of_birth: this.newUser.place_of_birth,

        };

        // Check if the password field is not empty before adding it to the data object
        if (this.newUser.password) {
          data.password = this.newUser.password;
        }

        const response = await axios.put(`/api/users/${user_id}`, data, { headers });

        if (response.status === 200) {
          window.alert('User updated Successfully');
          this.newUser = {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            license_no: '',
            role: '',
            second_role: '',
            category: '',
            department: '',
            date_of_birth: '',
            place_of_birth: '',
          };
          this.errorMessage = '';
          this.showAlert = false;
        }
        window.location.reload();
      } catch (error) {
        console.error('Error adding User:', error);

        if (error.response && error.response.data && error.response.data.errors) {
          // Handle validation errors
          const validationErrors = error.response.data.errors;

          // Build an error message from the validation errors
          let errorMessage = 'Validation errors:';
          for (const field in validationErrors) {
            errorMessage += `\n${field}: ${validationErrors[field][0]}`;
          }

          this.errorMessage = errorMessage;
        } else if (
      error.response &&
      error.response.status === 500 &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.includes('users_license_no_unique')
    ) {
      // Handle the specific duplicate entry error
      this.errorMessage = 'A User with this license_no already exists';
    } else if (
      error.response &&
      error.response.status === 500 &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.includes('users_email_unique')
    ) {
      // Handle the specific duplicate entry error
      this.errorMessage = 'A User with this Email already exists';
        } else {
          // Handle other errors
          //this.errorMessage = 'An error occurred while updating the User.';
        }

        this.showAlert = true;
      }
    },


    async fetchUsers() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/users', { headers });
        console.log(response);

        if (response.status === 200) {
          this.users = response.data.users;
        }
      } catch (error) {
        console.error('Error retrieving users:', error);
        // Handle error
      }
    },
    closeModalOutside() {
      this.$emit('close'); // Emit an event to indicate the modal should be closed
    },
    getUserData(user_id) {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        axios.get(`/api/users/${user_id}`, { headers })
          .then(res => {
            this.newUser = res.data;
          });
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    },
  },
  async mounted() {
    this.getUserData(localStorage.getItem('user_id'));
    await this.fetchUsers();

  },
}
</script>
  
<style scoped>
.trainee-list-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure the modal is on top of other content */
}

h2 {
  margin-top: 30px;
  font-size: 30px;
  margin-bottom: 30px;
}

/* Style your modal content */
.trainee-list-modal {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 800px;
  /* Limit the maximum width of the modal */
  padding: 20px;
  position: relative;
  max-height: 95%;
}


.modal-content {
  max-height: 90vh; /* Adjust the maximum height as needed */
  overflow-y: auto;
  /* Enable scrolling within the modal content */
}


.add button {
  width: 200px;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

p {
  padding: 20px;
  color: #be0808;
  border: 1px solid rgb(204, 202, 202);
  width: 70%;
  margin-left: 15%;
  border-radius: 5px;
  font-family: math;
}

button.btn:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

input.form-control,
select.form-control {
  width: 70%;
  margin-left: 150px;
  display: inline-block;
  margin-bottom: 25px;
  align-items: baseline;
}

.form-group {
  margin-bottom: 15px;
}

label {
  margin-right: 5px;
  position: absolute;
  margin-top: 8px;
}

.form-control {
  border-radius: 5px;
}

.form-control#start_date {
  border-radius: 5px;
  width: 25%;
  margin-left: 100px;
  margin-right: 45%;
}

h1 {
  margin-bottom: 20px;
}
</style>
  